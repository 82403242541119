import React from 'react'

import PrimaryLogo from 'assets/logos/htsLogoOutline.png'
import WhiteLogo from 'assets/ShieldLogoWhite.png'

import Text from 'components/common/Text'
import Card from 'components/common/cards/Card'
import colors from 'utility/colors'

const HTSPassedDeadline = ({
  dark,
  size = 'sm',
  link = false,
  containerStyle = {},
}) => {
  let backgroundColor = dark ? colors.RED : colors.WHITE
  let protectedByTextColor = dark ? colors.WHITE : colors.RED
  let htsTextColor = dark ? colors.WHITE : colors.RED
  let logo = dark ? WhiteLogo : PrimaryLogo

  let titleSize, protectedLineHeight, protectedSize, logoSize

  if (size === 'lg') {
    titleSize = 17
    protectedLineHeight = 16
    protectedSize = 14
    logoSize = 26
  } else if (size === 'xl') {
    titleSize = 17
    protectedLineHeight = 16
    protectedSize = 14
    logoSize = 32
  } else {
    titleSize = 14
    protectedLineHeight = 12
    protectedSize = 10
    logoSize = 20
  }

  return (
    <Card
      style={{
        backgroundColor: backgroundColor,
        display: 'inline-block',
        width: 'fit-content',
        padding: '10px 10px',
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...containerStyle,
      }}
    >
      <span>
        <Text
          color={protectedByTextColor}
          size={protectedSize}
          style={{
            padding: 0,
            margin: 0,
            lineHeight: `${protectedLineHeight}px`,
          }}
        >
          {2024}
        </Text>
        <Text
          color={htsTextColor}
          size={titleSize}
          style={{
            padding: 0,
            margin: 0,
            fontSize: titleSize,
            lineHeight: `${titleSize}px`,
          }}
        >
          Missed Deadline
        </Text>
      </span>
    </Card>
  )
}

export default HTSPassedDeadline
