import React from 'react'
import DataRow from 'components/common/rows/DataRow'
import HTSProtectedBlock from 'components/common/blocks/HTSProtectedBlock'
import { useApp } from 'components/providers/AppProvider'
import RowSubtitle from 'components/common/rows/RowSubtitle'

import colors from 'utility/colors'

const AddressRow = ({
	address,
	actions,
	propertyId,
	county,
	signedUp,
	highlight,
	subtitle,
	style,
	...props
}) => {
	const { breakpoints } = useApp()

	let rightCol

	if (signedUp) {
		rightCol = (
			<div
				style={{
					display: 'flex',
					justifyContent: breakpoints.md ? 'flex-end' : 'flex-start',
				}}
			>
				<HTSProtectedBlock dark containerStyle={{ marginRight: -10 }} />
			</div>
		)
	} else {
		const textStyle = {
			textAlign: breakpoints.md ? 'right' : 'left',
			fontSize: 16,
			color: colors.GRAY,
		}
		rightCol = (
			<div>
				<RowSubtitle style={textStyle}>{county}</RowSubtitle>
				<RowSubtitle style={textStyle}>{propertyId}</RowSubtitle>
			</div>
		)
	}

	return (
		<DataRow
			style={style}
			title={address}
			highlight={highlight}
			subtitle={subtitle}
			subtitleStyle={{
				color: colors.GRAY,
			}}
			actions={actions}
			rightColContent={rightCol}
			{...props}
		/>
	)
}

export default AddressRow
