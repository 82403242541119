import React from 'react';
import {parseGoogleResponse} from 'utility/google';
import PlacesAutocomplete from 'react-places-autocomplete';
import AddressRow from 'components/common/rows/AddressRow';
import FormInput from 'components/common/forms/elements/FormInput';
import FormLabel from 'components/common/forms/elements/FormLabel';

const AddressInput = ({google, useAutoComplete=true, mapRef, error, name='autoAddress', address, setAddress=() =>null, ...props}) => {
  const searchOptions = {
    types: ['address'],
    componentRestrictions: {
      country: ['us']
    }
  }

  return <>
    <PlacesAutocomplete
      value={address}
      searchOptions={searchOptions}
      shouldFetchSuggestions={useAutoComplete && address && address.length > 3}
      onChange={(address) => setAddress(address)}
      onSelect={(address, placeId) => {
        if(mapRef && google){
          const service = new google.maps.places.PlacesService(mapRef)
          service.getDetails({placeId}, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              const parsedAddress = parseGoogleResponse(results.address_components)
              const addressWithoutCountry = address.substring(0, address.lastIndexOf(",") + 1)
              const finalAddress = `${addressWithoutCountry} ${parsedAddress.zipCode ? parsedAddress.zipCode : ''}`
              setAddress(finalAddress)
            } else {
              setAddress(address)
            }
          })
        }
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          {props.label ? <FormLabel required={props.required} htmlFor={name ? name : ''}>
            {props.label}
          </FormLabel> : null}
          <FormInput
            type="text"
            name={name}
            onChange={(value) => setAddress(value)}
            value={address}
            error={error}
            {...getInputProps({
              placeholder: 'Search Places...'
            })}
          />

          <div className="autocomplete-dropdown-container">
            {/* {loading ? <div>Loading...</div> : null} */}
            {suggestions.map((suggestion, index) => {
              return <AddressRow 
                key={index}
                address={suggestion.formattedSuggestion.mainText}
                county={suggestion.formattedSuggestion.secondaryText}
                {...getSuggestionItemProps(suggestion)}
                />
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  </>
}

export default AddressInput;