import React from 'react'
import _ from 'underscore'
import PropertyRow from 'components/common/rows/PropertyRow'
import Subtitle from 'components/common/Subtitle'
import colors from 'utility/colors'

/**
 * Display the selected properties that the user has choosen
 * and give them the option to remove them.
 *
 * This component connects to Global State because it edits
 * selected properties directly
 */
const SelectProperties = ({
	plan,
	selectedProperties = [],
	primaryColor,
	updateProperty,
	inputUser = {},
	hideTitles = false,
	onRemoveProperty = () => console.log('Removed'),
	style = {},
	labelColor,
	hideEdit = false,
	hideSavings = false,
	hideOwnerCheck = false,
	hideOwnerDetails = false,
	hideBadges = false,
	hideDeadlineBadges = false,
	...props
}) => {
	if (!selectedProperties || !selectedProperties.length) return null

	return (
		<div style={style}>
			{hideTitles ? null : (
				<Subtitle
					style={{
						color: colors.GRAY,
						fontWeight: 'bold',
						fontSize: '14px',
						marginBottom: 7,
					}}
				>
					MY PROPERTIES
				</Subtitle>
			)}
			{/* <Text style={{marginBottom: 10}}>After you add your residence, search for any rental properties you own and add them to the list!</Text> */}
			{/* {hideTitles ? null : <Text>If owner name is incorrect, please select the edit icon <FaEdit size="16px" color={COLORS.PRIMARY} style={{display: 'inline'}}/> to correct it.</Text>} */}
			<div style={{ textAlign: 'left' }}>
				{selectedProperties
					.sort((a, b) => b.added - a.added)
					.map((property, index) => {
						if (!property) return null

						return (
							<PropertyRow
								key={index}
								plan={plan}
								withCard
								inputUser={inputUser}
								primaryColor={primaryColor}
								updateProperty={updateProperty}
								onRemove={() => onRemoveProperty(property)}
								style={{ marginTop: 5, marginBottom: 5 }}
								property={property}
								primary={property.isPrimary}
								withNameAndAddress={!hideOwnerDetails}
								editable={!hideEdit}
								hideSavings={hideSavings}
								hideOwnerCheck={hideOwnerCheck}
								hideBadges={hideBadges}
								hideDeadlineBadges={hideDeadlineBadges}
							/>
						)
					})}
			</div>
		</div>
	)
}

export default SelectProperties
