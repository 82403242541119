import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'utility/colors';

const StyledRowTitle = styled.p`
  color: ${COLORS.PRIMARY};
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;
`;

const RowTitle = ({children, ...props}) => {
  return <StyledRowTitle {...props}>{children}</StyledRowTitle>
}

export default RowTitle;