import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { GoogleApiWrapper } from 'google-maps-react'

import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import parser from 'parse-address'
import getNested from 'utility/getNested'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

const Map = dynamic(() => import('components/common/Map'))
import Text from 'components/common/Text'
import AddressInput from 'components/common/forms/elements/AddressInput'
import FormLabel from 'components/common/forms/elements/FormLabel'
import FormInput from 'components/common/forms/elements/FormInput'
import Button from 'components/common/Button'

const EditPropertyFormContainer = styled.form`
	margin-top: 20px;
	margin-bottom: 20px;
`

const FormContainer = styled.div`
	margin-top: 200px;
`

const EditPropertyForm = ({ property, closeModal, updateProperty, google }) => {
	const { data: tenantProfile } = useTenantProfile()
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	const [mapReference, setMapRef] = useState(null)

	const validateFormValues = async (values) => {
		const errors = {}
		if (values.ownerName) values.ownerName = values.ownerName.trim()

		if (!values.ownerName) {
			errors.ownerName = 'Owner name is required'
		}

		if (values.ownerName.length < 3) {
			errors.ownerName = 'Owner name must have 3 or more characters'
		}

		if (!values.ownerAddress) {
			errors.ownerAddress = 'Owner address is required'
		} else {
			const parsedAddress = parser.parseLocation(values.ownerAddress)
			const ownerAddress = `${parsedAddress.number ? parsedAddress.number : ''} ${
				parsedAddress.prefix ? parsedAddress.prefix : ''
			} ${parsedAddress.street ? parsedAddress.street : ''} ${
				parsedAddress.type ? parsedAddress.type : ''
			}`
				.replace(/\s\s+/g, ' ')
				.trim()
			const ownerCity = parsedAddress.city
			const ownerState = parsedAddress.state
			const ownerZipCode = parsedAddress.zip
			if (!ownerAddress || !ownerCity || !ownerState || !ownerZipCode) {
				errors.ownerAddress = 'Address must be in complete. Ex. 100 Main Street Dallas, TX 75208'
			}
		}
		return errors
	}

	const formik = useFormik({
		initialValues: {
			// ownerName: getNested(property, "htsData", "owner", "name"),
			ownerAddress: getNested(property, 'address'),
		},
		onSubmit: async (values) => {
			const parsedAddress = parser.parseLocation(values.ownerAddress)
			const fullAddress = values.ownerAddress
			const ownerAddress = `${parsedAddress.number ? parsedAddress.number : ''} ${
				parsedAddress.prefix ? parsedAddress.prefix : ''
			} ${parsedAddress.street ? parsedAddress.street : ''} ${
				parsedAddress.type ? parsedAddress.type : ''
			}`
				.replace(/\s\s+/g, ' ')
				.trim()
			const ownerCity = parsedAddress.city
			const ownerState = parsedAddress.state
			const ownerZipCode = parsedAddress.zip

			const updatedProperty = {
				...property,
				htsData: {
					...property.htsData,
					owner: {
						...property.owner,
						fullAddress,
						name: values.ownerName,
						address: ownerAddress,
						city: ownerCity,
						state: ownerState,
						zip: ownerZipCode,
					},
				},
			}
			updateProperty(updatedProperty)
			// editProperty(dispatch, updatedProperty)
			closeModal()
		},
		validate: validateFormValues,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const onMapReady = (mapProps, map) => {
		setMapRef(map)
		let geocoder = new google.maps.Geocoder(map)
		if (geocoder) {
			geocoder.geocode({ address: getNested(property, 'address') }, function (results, status) {
				if (
					status == google.maps.GeocoderStatus.OK &&
					status != google.maps.GeocoderStatus.ZERO_RESULTS
				) {
					map.setCenter(results[0].geometry.location)
					new google.maps.Marker({
						position: results[0].geometry.location,
						map: map,
						title: getNested(property, 'address'),
					})
				}
			})
		}
	}
	const initialCoords = { lat: 32.7767, lng: -96.797 }

	return (
		<EditPropertyFormContainer onSubmit={formik.handleSubmit}>
			<Map
				initialCenter={initialCoords}
				onReady={onMapReady}
				containerStyle={{
					position: 'absolute',
					width: '100%',
					height: 'inherit',
					top: 0,
					left: 0,
					right: 0,
				}}
				style={{ width: '100%', height: '200px' }}
				zoom={14}
			/>
			<FormContainer>
				<FormLabel>
					Property Address:{' '}
					{property && (
						<Text bold style={{ display: 'inline', color: 'black' }}>
							{getNested(property, 'htsData', 'address')}
						</Text>
					)}
				</FormLabel>

				<FormInput
					label={'Owner Name'}
					type='text'
					id='ownerName'
					name='ownerName'
					placeholder={getNested(property, 'htsData', 'owner', 'name')}
					onChange={formik.handleChange}
					value={formik.values.ownerName}
					error={formik.errors.ownerName}
					required
				/>

				<AddressInput
					label={'Owner Mailing Address'}
					required
					mapRef={mapReference}
					google={google}
					error={formik.errors.ownerAddress}
					address={formik.values.ownerAddress}
					setAddress={(address) => {
						formik.setFieldValue('ownerAddress', address)
					}}
				/>

				<Row style={{ marginTop: 20 }}>
					<Col>
						<Button color={'black'} background={'white'} onClick={() => closeModal()}>
							Cancel
						</Button>
					</Col>
					<Col>
						<Button type='submit' background={secondaryColor}>
							Save
						</Button>
					</Col>
				</Row>
			</FormContainer>
		</EditPropertyFormContainer>
	)
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyC4naCkz07mC-gzOSLlktir6SL0OhCWyxc',
})(EditPropertyForm)
