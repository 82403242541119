import React from 'react';
import styled from 'styled-components';
import colors from 'utility/colors';

const StyledRowSubtitle = styled.p`
  font-size: 14px;
  margin-bottom: 2px;
  color: ${colors.LIGHT};
`;

const RowSubtitle = ({children, ...props}) => {
  return <StyledRowSubtitle {...props}>{children}</StyledRowSubtitle>
}

export default RowSubtitle;