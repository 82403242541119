import React, { useState } from 'react'
import styled from 'styled-components'
import { FaTimes, FaEdit } from 'react-icons/fa'

import DataRow from 'components/common/rows/DataRow'

import RowTitle from 'components/common/rows/RowTitle'
import RowSubtitle from 'components/common/rows/RowSubtitle'
import Modal from 'components/common/Modal'
import EditPropertyForm from 'components/common/forms/EditPropertyForm'
import DataCard from 'components/common/cards/DataCard'
import HTSProtectedBlock from 'components/common/blocks/HTSProtectedBlock'
import HTSPassedDeadline from 'components/common/blocks/HTSPassedDeadline'
import { useApp } from 'components/providers/AppProvider'
import getNested from 'utility/getNested'
import { useGroup } from 'components/providers/GroupProvider'
import colors from 'utility/colors'
import { formatMoneyCents, toMoney } from 'utility/numbers'

const Icon = styled.span`
	:hover {
		cursor: pointer;
	}
`

const PropertyRow = ({
	inputUser,
	plan,
	property,
	onRemove,
	updateProperty,
	withNameAndAddress = true,
	editable = true,
	removeable = true,
	hideOwnerCheck = false,
	hideBadges = false,
	hideDeadlineBadges = false,
	style,
	withCard,
}) => {
	const grayColor = colors.GRAY

	const { breakpoints } = useApp()
	const { businessNameKeywords } = useGroup()
	const [selectedProperty, setSelectedProperty] = useState(null)

	const signedUpAndProtected = getNested(property, 'meta', 'signupState', 'signedUpAndProtected')
	const displayContactUs = getNested(property, 'meta', 'signupState', 'contactRequired')
	const displayMissedDeadlie =
		!hideDeadlineBadges && getNested(property, 'meta', 'signupState', 'missedDeadline')

	const propertyAddress = getNested(property, 'htsData', 'address')
	// If the property is signed up, reference the meta, othwerwise, refernce the owner from propertyDetails (Data side)
	const ownerName = signedUpAndProtected
		? getNested(property, 'htsGroup', 'aOfAOwner', 'name')
		: getNested(property, 'htsData', 'owner', 'name')

	const propertyId = getNested(property, 'htsData', 'county', 'propertyId')
	const countyName = getNested(property, 'htsGroup', 'countyName')

	const ownerAddress = signedUpAndProtected
		? `${getNested(property, 'htsGroup', 'aOfAOwner', 'address') || ''} ${
				getNested(property, 'htsGroup', 'aOfAOwner', 'cityStateZip') || ''
		  }`
		: getNested(property, 'htsData', 'owner', 'fullAddress')
	const legalDescription = getNested(property, 'htsData', 'legalDescription')
	const ownerNamePieces = ownerName
		? ownerName
				.toLowerCase()
				.replace(/[\W_]+/g, ' ')
				.split(' ')
		: []
	const firstNamePieces = inputUser.firstName
		? inputUser.firstName
				.replace(/[\W_]+/g, ' ')
				.toLowerCase()
				.split(' ')
		: []
	const lastNamePieces = inputUser.lastName
		? inputUser.lastName
				.replace(/[\W_]+/g, ' ')
				.toLowerCase()
				.split(' ')
		: []

	let ignoreOwnerCheck = hideOwnerCheck
	let doesOwnerNameMatchInputName = false
	let ownerNameIsBusiness = false
	let userNameIsBusiness = false

	if (ownerNamePieces && ownerNamePieces.length) {
		ownerNameIsBusiness = !!businessNameKeywords.filter((keyword) => {
			return ownerNamePieces.includes(keyword.toLowerCase())
		}).length
	}

	if (firstNamePieces && firstNamePieces.length && lastNamePieces && lastNamePieces.length) {
		userNameIsBusiness = !!businessNameKeywords.filter((keyword) => {
			return (
				firstNamePieces.includes(keyword.toLowerCase()) ||
				lastNamePieces.includes(keyword.toLowerCase())
			)
		}).length
	}

	if (
		ownerNamePieces &&
		ownerNamePieces.length &&
		firstNamePieces &&
		firstNamePieces.length &&
		lastNamePieces &&
		lastNamePieces.length
	) {
		const firstNameIncluded = firstNamePieces.filter((piece) =>
			ownerNamePieces.includes(piece.toLowerCase()),
		).length
		const lastNameIncluded = lastNamePieces.filter((piece) =>
			ownerNamePieces.includes(piece.toLowerCase()),
		).length
		if (firstNameIncluded || lastNameIncluded) {
			doesOwnerNameMatchInputName = true
		}
	} else {
		doesOwnerNameMatchInputName = true
	}

	const nameCheckError =
		!ignoreOwnerCheck && !ownerNameIsBusiness && !doesOwnerNameMatchInputName && !userNameIsBusiness

	const businessCheckError = !ignoreOwnerCheck && userNameIsBusiness

	const nameError = nameCheckError || businessCheckError

	let rightColumnContent
	if (signedUpAndProtected) {
		rightColumnContent = (
			<div
				style={{
					display: 'flex',
					justifyContent: breakpoints.md ? 'flex-end' : 'flex-start',
				}}
			>
				<HTSProtectedBlock dark containerStyle={{ marginRight: -10 }} />
			</div>
		)
	} else if (displayMissedDeadlie) {
		rightColumnContent = (
			<div
				style={{
					display: 'flex',
					justifyContent: breakpoints.md ? 'flex-end' : 'flex-start',
				}}
			>
				<HTSPassedDeadline dark containerStyle={{ marginRight: -10 }} />
			</div>
		)
	} else {
		const projectedValues = getNested(property, 'htsGroup', 'propertyTaxYear', 'projectedValues')
		const estTaxSavings = formatMoneyCents(getNested(projectedValues, 'taxSavingsInCents'))
		rightColumnContent = (
			<div
				style={{
					display: 'flex',
					justifyContent: breakpoints.md ? 'flex-end' : 'flex-start',
				}}
			>
				<DataCard
					title=''
					noShadow
					cardStyle={{ padding: 0, paddingLeft: 0, paddingRight: 0 }}
					titleStyle={{ textAlign: breakpoints.lg ? 'right' : 'left' }}
					valueStyle={{
						textAlign: breakpoints.lg ? 'right' : 'left',
						color: grayColor,
					}}
					data={
						plan
							? [
									{
										// title: 'Plan Price',
										value: `$${toMoney(formatMoneyCents(plan?.membershipFeeAmountInCents))}`,
									},
							  ]
							: []
					}
				/>
			</div>
		)
	}

	if (hideBadges) {
		rightColumnContent = null
	}

	return (
		<>
			{editable ? (
				<Modal
					open={selectedProperty}
					showCancel={false}
					setClose={() => setSelectedProperty(null)}
					showSave={false}
					title={''}
				>
					<EditPropertyForm
						updateProperty={updateProperty}
						property={property}
						closeModal={() => setSelectedProperty(null)}
					/>
				</Modal>
			) : null}

			<DataRow
				size={breakpoints.lg ? 8 : 12}
				withCard={withCard}
				noShadow
				// withRightColumn={editable && removeable}
				style={style}
				title={<RowTitle>{propertyAddress}</RowTitle>}
				subtitle={
					withNameAndAddress ? (
						<>
							<RowSubtitle>
								<b
									style={{
										color: grayColor,
									}}
								>
									{countyName} - {propertyId}
								</b>
							</RowSubtitle>
							<RowSubtitle>
								Owner Name:{' '}
								<b
									onClick={() => setSelectedProperty(property)}
									style={{
										color: nameError ? 'red' : grayColor,
										cursor: 'pointer',
									}}
								>
									{ownerName}
								</b>
							</RowSubtitle>

							{nameCheckError && (
								<RowSubtitle
									style={{
										color: 'red',
										marginBottom: 10,
										cursor: 'pointer',
									}}
									onClick={() => setSelectedProperty(property)}
								>
									A friendly warning that your first/last name don't match the owner name in county
									records, which is required. If the owner shown here is the previous owner you
									purchased from, please click{' '}
									<b>
										<u>here</u>
									</b>{' '}
									to change the owner name to your name before you signup.
								</RowSubtitle>
							)}

							{ownerNameIsBusiness && (
								<RowSubtitle
									style={{ color: 'red', marginBottom: 10, cursor: 'pointer' }}
									onClick={() => setSelectedProperty(property)}
								>
									Make sure this is a legal entity YOU own/control. If the owner shown here is the
									previous owner you purchased from, please click{' '}
									<b>
										<u>here</u>
									</b>{' '}
									to change the owner name to your name before you signup.
								</RowSubtitle>
							)}

							{businessCheckError && (
								<RowSubtitle
									style={{ color: 'red', marginBottom: 10, cursor: 'pointer' }}
									onClick={() => setSelectedProperty(property)}
								>
									Only a person can sign the form. Please remove "LLC" or "Trust" or "INC" from the
									first and last name fields. Be sure to use the first and last name of a person
									that can legally sign for the entity.
								</RowSubtitle>
							)}

							<RowSubtitle>
								Owner Address: <b style={{ color: grayColor }}>{ownerAddress}</b>
							</RowSubtitle>
							<RowSubtitle>
								Legal Description: <b style={{ color: grayColor }}>{legalDescription}</b>
							</RowSubtitle>
						</>
					) : null
				}
				rightColStyle={{
					marginRight: -15,
					paddingRight: 0,
				}}
				rightColContent={rightColumnContent}
				actions={
					editable || removeable ? (
						<>
							{editable && !signedUpAndProtected && !displayContactUs && !displayMissedDeadlie ? (
								<Icon>
									<FaEdit
										size='18px'
										color={grayColor}
										style={{ marginRight: 15, marginLeft: 5 }}
										onClick={() => setSelectedProperty(property)}
									/>
								</Icon>
							) : null}
							{removeable ? (
								<Icon>
									<FaTimes size='18px' color='red' onClick={() => onRemove()} />
								</Icon>
							) : null}
						</>
					) : null
				}
			/>
		</>
	)
}

export default PropertyRow
