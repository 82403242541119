import ReactGA from 'react-ga'
import { event as GA4Event } from 'nextjs-google-analytics'

import { each } from 'underscore'
import environment from 'utility/environment'

import isBrowser from 'utility/isBrowser'
const isProductionOrReviewAndBrowser = (environment.production || environment.review) && isBrowser

// Todo - white label - change these out & make them dynamic
export const GA_TRACKING_ID = 'UA-163685091-1' // This is your GA Tracking ID
export const GA4_TRACKING_ID = environment.production ? 'G-R78J2SJWBM' : 'G-190D5GHELB' // This is your GA4 Measurement id

export const init = () => {
	ReactGA.initialize(GA_TRACKING_ID)
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
	if (isProductionOrReviewAndBrowser) {
		ReactGA.pageview(window?.location?.pathname + window?.location?.search)
		// GA4PageView({
		//   location: window?.location?.href,
		//   path: window?.location?.pathname + window?.location?.search,
		// })
	} else {
		console.log('GA Page View (development): ', url)
	}
}

export const event = ({ action, category, label, value, ...data }) => {
	if (isProductionOrReviewAndBrowser) {
		ReactGA.event({
			category,
			action,
			label,
			value,
		})
	} else {
		console.log('GA Event (development): ', action)
	}
	if (isProductionOrReviewAndBrowser) {
		GA4Event(
			action,
			{
				category,
				label,
				value,
				...data,
			},
			GA4_TRACKING_ID,
		)
		window.dataLayer?.push({
			action,
			category,
			label,
			value,
			event: action,
			...data,
		})
	}
}

export function parseGoogleResponse(components) {
	let addressObj = {}
	each(components, function (component) {
		each(component.types, function (type) {
			if (type === 'street_number') addressObj.number = component.long_name
			if (type === 'route') addressObj.street = component.long_name
			if (type === 'locality') addressObj.city = component.long_name
			if (type === 'administrative_area_level_1') addressObj.state = component.short_name
			if (type === 'country') addressObj.country = component.short_name
			if (type === 'postal_code') addressObj.zipCode = component.long_name
		})
	})

	return {
		...addressObj,
		formatted:
			`${addressObj.number} ${addressObj.street} ${addressObj.city}, ${addressObj.state} ${addressObj.zipCode}`
				.replace(/\s\s+/g, ' ')
				.trim(),
	}
}
