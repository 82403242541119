import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styled, { css, keyframes } from 'styled-components'
import RowTitle from 'components/common/rows/RowTitle'
import RowSubtitle from 'components/common/rows/RowSubtitle'
import Card from 'components/common/cards/Card'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const animation = (props) => css`
	${pulse} 1s infinite alternate;
`

const HighlightedBackground = styled.div`
	background-color: ${({ color }) => color};
	position: absolute;
	z-index: 1;
	margin-bottom: 10px;
	margin-top: 10px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	animation: ${animation};
`

const StyledRow = styled(Row)`
	position: static;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 10px;
	margin-top: 10px;
	:hover {
		cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
	}
`

// const Col = styled.div`
//   flex-wrap: wrap;
//   /* overflow: hidden; */
//   text-overflow: ellipsis;
// `

const Action = styled.div`
	/* @media (max-width:768px)  { */
	z-index: 9;
	background-color: white;
	border-radius: 20px;
	padding: 5px;
	box-shadow: 0 2px 10px 0 rgba(44, 44, 44, 0.1);
	position: absolute;
	top: -5px;
	right: -5px;
	/* } */
`

const RightColumn = styled(Col)`
	text-align: right;
	@media (max-width: 768px) {
		text-align: left;
	}
`

const DataRow = ({
	title = '',
	subtitle = '',
	highlight = false,
	subtitleStyle,
	children,
	actions,
	size = 7,
	rightColStyle,
	rightColContent,
	style,
	onClick,
	withCard = false,
	...props
}) => {
	const { data: tenantProfile } = useTenantProfile()
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	const isTitleString = typeof title === 'string'
	const isSubtitleString = typeof subtitle === 'string'
	const baseRow = (
		<StyledRow style={style} onClick={props.onClick ? props.onClick : onClick} {...props}>
			{highlight && <HighlightedBackground color={secondaryColor} />}
			<Col xs='12' md={rightColContent ? size : '12'} style={{ zIndex: 99 }}>
				{isTitleString ? <RowTitle>{title}</RowTitle> : title}
				{isSubtitleString ? <RowSubtitle style={subtitleStyle}>{subtitle}</RowSubtitle> : subtitle}
			</Col>
			{rightColContent ? (
				<RightColumn xs='12' md={12 - size} style={{ zIndex: 99, ...rightColStyle }}>
					{rightColContent}
				</RightColumn>
			) : null}
			{actions ? <Action>{actions}</Action> : null}
			{children}
		</StyledRow>
	)

	if (withCard)
		return (
			<Card style={{ position: 'relative', marginBottom: 10 }} {...props}>
				{baseRow}
			</Card>
		)

	return baseRow
}

export default DataRow
